import React, { useState, useEffect, useContext, useRef } from "react";
import "./styles.scss";
import api from "../../utils/api";
import { QuizContext } from "../ContextProvider";

const delay = ms => new Promise(res => setTimeout(res, ms));

const timerCount = 10;

const PlayScreen = React.memo(function PlayScreen(props) {
  const useQuizContext = useContext(QuizContext);
  const [counter, setCounter] = React.useState(0);
  const [timerWidth, updateTimerWidth] = useState(100);
  const [questionArray, updateQuestionArray] = useState([]);
  const [questionIndex, updateQuestionIndex] = useState(0);
  const [showRoundText, updateShowRoundText] = useState(true);
  const [answeredOption, updateAnsweredOption] = useState(-98);
  const [answeredTime, updateAnsweredTime] = useState(10);
  //const [questionId, updateQuestionId] = useState(-1);
  const [correctAnswer, updateCorrectAnswer] = useState(-98);
  //const [answerRespObj, updateAnswerResponse] = useState({});
  const [currentUserPoints, updateCurrentUserPoints] = useState(0);
  const [opponentUserPoints, updateOpponentUserPoints] = useState(0);

  const user_id = useQuizContext.userId;
  const room_id = useQuizContext.roomId;

  let roomDetails =
    useQuizContext.roomDetails && useQuizContext.roomDetails.users;
  let userArrUtil = Object.keys(roomDetails);
  let opponentUserUtil = {};
  let currentUserUtil = {};

  if (userArrUtil.length > 1) {
    opponentUserUtil = userArrUtil.filter(item => item !== user_id);
    opponentUserUtil = roomDetails[opponentUserUtil];
    currentUserUtil = roomDetails[user_id];
  }
  //debugger;

  useEffect(() => {
    const room_id = useQuizContext.roomId;
    const quiz_id =
      useQuizContext.liveQuizData &&
      useQuizContext.liveQuizData.quiz &&
      useQuizContext.liveQuizData.quiz[0].id;
    const getQuestions = async () => {
      const action = Object.assign({});
      action.payload = { room_id, quiz_id };
      action.handler = "GET_QUESTIONS";
      const response = await api(action);
      if (response.status === 200) {
        updateQuestionArray(response.data);
        // updateShowRoundText(true);
      } else {
        console.log("fetch questions fail");
      }
    };
    getQuestions();
  }, []);

  const fetchLiveScore = async () => {
    const action_1 = Object.assign({});
    action_1.handler = "FETCH_LIVE_SCORES";
    action_1.payload = {
      room_id,
      user_id
    };
    const response_1 = await api(action_1);
    if (response_1.status === 200) {
      updateCurrentUserPoints(response_1.data.data.currentUser.points);
      updateOpponentUserPoints(response_1.data.data.opponentUser.points);
    } else {
      console.log("fetch live score failed");
    }
  };

  const submitAnswer = async () => {
    //debugger;
    const questionId =
      questionArray.length && questionArray[questionIndex - 1].id;

    const action = Object.assign({});
    const action_1 = Object.assign({});
    action.handler = "POST_ANSWER";
    action_1.handler = "FETCH_LIVE_SCORES";
    action.payload = {
      id: questionId,
      answer: answeredOption,
      room_id,
      user_id,
      at_second: 10 - answeredTime
    };
    action_1.payload = {
      room_id,
      user_id
    };
    if (room_id > 0 && Number.parseInt(user_id, 10) > 0 && questionId > 0) {
      const response = await api(action);
      if (response.status === 200) {
        updateCorrectAnswer(response.data.data);
        const response_1 = await api(action_1);
        if (response_1.status === 200) {
          updateCurrentUserPoints(response_1.data.data.currentUser.points);
          updateOpponentUserPoints(response_1.data.data.opponentUser.points);
        }
      } else {
        console.error("submit answer failed");
      }
    }
  };
  useEffect(() => {
    let timer = "";
    const updateAllStatesAfterEachQuestion = async () => {
      const updatedTimerWidth = ((counter - 1) / timerCount) * 100;
      timer =
        counter > 0 &&
        setInterval(() => {
          setCounter(counter - 1);
          updateTimerWidth(updatedTimerWidth);
        }, 1000);
      if (counter == 0) {
        submitAnswer();
        await delay(3000);
        console.log(questionIndex);
        console.log(questionArray.length);
        console.log(
          questionIndex ===
            (questionArray.length == 0
              ? questionArray.length + 1
              : questionArray.length)
        );
        if (
          questionIndex ===
          (questionArray.length == 0
            ? questionArray.length + 1
            : questionArray.length)
        ) {
          props.history.replace("/result");
        } else {
          updateQuestionIndex(questionIndex + 1);
          updateShowRoundText(true);
          updateAnsweredOption(-98);
          updateCorrectAnswer(-98);
          //slider.current.slickGoTo(questionIndex + 1);
        }

        //setTimeout(() => props.history.push("/result"), 1000);
      }
      // if (counter === 8 && questionIndex > 0) {
      //   fetchLiveScore();
      // }
    };
    updateAllStatesAfterEachQuestion();
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  useEffect(() => {
    const updateQuestionState = async () => {
      await delay(2000);
      fetchLiveScore();
      await delay(1000);
      setCounter(timerCount);
      updateShowRoundText(false);
    };
    if (showRoundText) {
      updateQuestionState();
    }
  }, [showRoundText]);

  const handleOptionClick = (optionNumber, questionId) => {
    updateAnsweredOption(optionNumber);
    updateAnsweredTime(counter);
    // updateQuestionId(questionId);
  };

  const question =
    (questionArray.length && questionArray[questionIndex - 1]) || {};

  return (
    <div>
      <div className="playsceen">
        <div className="root_videobkg">
          <video loop muted autoPlay playsInline className="playscreenVideo">
            <source
              src="https://onequiz.games.onefc.com/assets/pvp/ONE_QUIZ_bkg.mp4"
              type="video/mp4"
              loop={true}
            ></source>
          </video>
        </div>
        {showRoundText ? (
          ""
        ) : (
          <div>
            <div className="vl"></div>
            <div className="v2"></div>
            <div className="leftheight">
            <div
          className="leftborder"
          style={{
            height: `${(currentUserPoints/ (questionArray.length*10))*100}%`,

            transition: "height 0.5s ease"
          }}
        ></div>
            </div>
            <div className="rightheight">
            <div
          className="rightborder"
          style={{
            height: `${(opponentUserPoints/ (questionArray.length*10))*100}%`,
            transition: "height 0.5s ease"
          }}
        ></div>
            </div>
          </div>
          
        )}

       
        <div className="root_row">
          <div className="userDetail">
            <div className="play_bluering">
              <img
                src={currentUserUtil && currentUserUtil.avatar}
                className="userImg"
                alt=""
              ></img>
            </div>
            <div>
              <div className="userName">
                {currentUserUtil && currentUserUtil.name && currentUserUtil.name.length >=8?(currentUserUtil.name.substring(0, 8) + ".."):(currentUserUtil.name) }
              </div>
            </div>
          </div>

          <div className="root_scorebox">
            <div className="userPoint">{currentUserPoints}</div>
            <div className="oppuserPoint">{opponentUserPoints}</div>
          </div>

          <div className="opponentDetail">
            <div>
              <div className="oppUserName">
                {opponentUserUtil && opponentUserUtil.name && opponentUserUtil.name.length >=8?(opponentUserUtil.name.substring(0, 8) + ".."):opponentUserUtil.name }
              </div>
            </div>
            <div className="play_redring">
              <img
                src={opponentUserUtil && opponentUserUtil.avatar}
                className="oppUser"
                alt=""
              ></img>
            </div>
          </div>
        </div>

        {question ? (
          showRoundText ? (
            <div className="round_text">
              Round
              <div className="round_no">
                {questionIndex === 0 ? 1 : questionIndex} of{" "}
                {questionArray.length}
              </div>
            </div>
          ) : (
            <div>
              {question ? (
                <div>
                  <div className="quesBox">
                    {question.question_image ? (
                      <React.Fragment>
                        <div className="quesImg">{question.question_text}</div>
                        <div className="img_question">
                          <img src={question.question_image} />
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="ques">{question.question_text}</div>
                    )}
                  </div>
                  <div
                    className={
                      question.question_image
                        ? "root_countdownLarge"
                        : "root_countdown"
                    }
                  >
                    <div className="leftline">
                      <div
                        className="innerLineLeft"
                        style={{ width: `${timerWidth}%` }}
                      ></div>
                    </div>
                    <div className="count_down">{counter}</div>
                    <div className="rightline">
                      <div
                        className="innerLineRight"
                        style={{ width: `${timerWidth}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="optionsContainer">
                    <button
                      className={
                        counter === 0 && correctAnswer.correct_answer > 0
                          ? correctAnswer.correct_answer === 1
                            ? "option1 correct"
                            : answeredOption === 1
                            ? "option1 incorrect"
                            : "option1"
                          : answeredOption === 1
                          ? "option1 selected"
                          : "option1"

                        //answeredOption === 1 ? "option1 selected" : "option1"
                      }
                      onClick={handleOptionClick.bind(null, 1, question.id)}
                      style={
                        answeredOption !== -98 || counter === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                    >
                      {question.question_option_1}
                    </button>
                    <button
                      className={
                        counter === 0 && correctAnswer.correct_answer > 0
                          ? correctAnswer.correct_answer === 2
                            ? "option1 correct"
                            : answeredOption === 2
                            ? "option1 incorrect"
                            : "option1"
                          : answeredOption === 2
                          ? "option1 selected"
                          : "option1"
                      }
                      onClick={handleOptionClick.bind(null, 2, question.id)}
                      style={
                        answeredOption !== -98 || counter === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                    >
                      {question.question_option_2}
                    </button>
                    <button
                      className={
                        counter === 0 && correctAnswer.correct_answer > 0
                          ? correctAnswer.correct_answer === 3
                            ? "option1 correct"
                            : answeredOption === 3
                            ? "option1 incorrect"
                            : "option1"
                          : answeredOption === 3
                          ? "option1 selected"
                          : "option1"
                      }
                      onClick={handleOptionClick.bind(null, 3, question.id)}
                      style={
                        answeredOption !== -98 || counter === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                    >
                      {question.question_option_3}
                    </button>
                    <button
                      className={
                        counter === 0 && correctAnswer.correct_answer > 0
                          ? correctAnswer.correct_answer === 4
                            ? "option1 correct"
                            : answeredOption === 4
                            ? "option1 incorrect"
                            : "option1"
                          : answeredOption === 4
                          ? "option1 selected"
                          : "option1"
                      }
                      onClick={handleOptionClick.bind(null, 4, question.id)}
                      style={
                        answeredOption !== -98 || counter === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                    >
                      {question.question_option_4}
                    </button>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          )
        ) : (
          <div />
        )}
      </div>
      <div className="overlay2"></div>
    </div>
  );
});

export default PlayScreen;
