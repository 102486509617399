import React, { Component } from "react";
import ReactDOM from "react-dom";

// const Music = () => {
//   useEffect(() => {
//     const musicDiv = document.getElementById("audio-root");
//     if (musicDiv) {
//     }
//     return ()=>{}
//   }, []);
//   return props.children;

// };

const musicRoot = document.getElementById("audio-root");

class Music extends Component {
  constructor(props) {
    super(props);
    this.htmlStr = `<audio id="id_audio" autoPlay loop>
        <source
          src="https://onequiz.games.onefc.com/assets/bkgmusic.mp3"
          type="audio/mp3"
        ></source>
        </audio>`;
    this.el = document.createElement("div");
    this.el.innerHTML = this.htmlStr;
  }

  componentDidMount() {
    musicRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    musicRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Music;
