import React, { useState, useEffect, useContext } from "react";
import "./styles.scss";
import { QuizContext } from "../ContextProvider";
import api from "../../utils/api";

const ResultScreen = React.memo(function ResultScreen(props) {
  const useQuizContext = useContext(QuizContext);
  const [resultsArr, updateResultsArr] = useState([]);
  const userId = useQuizContext.userId;
  // const quiz_id =
  //   useQuizContext.liveQuizData &&
  //   useQuizContext.liveQuizData.quiz &&
  //   useQuizContext.liveQuizData.quiz[0].id;

  useEffect(() => {
    const roomId = useQuizContext.roomId;

    const fetchResults = async () => {
      const action = Object.assign({});
      action.handler = "FETCH_RESULTS";
      action.payload = { room_id: roomId, user_id: userId };

      const response = await api(action);

      if (response.status === 200) {
        console.log(response.data.data);
        updateResultsArr(response.data.data);
      } else {
        console.log("fetch room failed in results");
      }
    };
    if (roomId > 0) {
      fetchResults();
    }

    // const response = {
    //   success: true,
    //   data: {
    //     has_won: "lost",
    //     currentUser: {
    //       id: "5f807676c779230010e52150",
    //       points: 0,
    //       user_type: "guest",
    //       name: "Guest1017",
    //       email: "",
    //       country: "IN",
    //       analytics_id: "ARNf2EQYxnXXaN6Y5pgjFenjH4l2",
    //       avatar: "https://res.cloudinary.com/onefc/image/upload/avatar/15.jpg"
    //     },
    //     opponentUser: {
    //       points: 19,
    //       user_type: "login",
    //       analytics_id: "89x9VzyFaYhG3JE6sDOFUXBgka23",
    //       name: "Shailesh Jaiswar",
    //       email: "shail.jaiswar3@gmail.com",
    //       avatar:
    //         "https://lh3.googleusercontent.com/a-/AOh14GigqHQUB9_LVoWGNG_yljk4Px5Fw_HN3OWxU7U8=s96-c",
    //       country: "US"
    //     }
    //   }
    // };

    // updateResultsArr(response.data);
  }, []);

  if (resultsArr.has_won) {
    let headertext = "";
    switch (resultsArr.has_won) {
      case "won":
        headertext = "YOU WON";
        break;
      case "lost":
        headertext = "YOU LOSE, TRY AGAIN";
        break;
      case "tie":
        headertext = "SCORES TIED";
        break;
      default:
        headertext = "AWAITING FINAL RESULTS";
        break;
    }

    return (
      <div>
        <div className="resultScreen">
          <video
            loop
            muted
            autoPlay
            playsInline
            style={{ position: "absolute", zIndex: "-1" }}
            className="video"
          >
            <source
              src="https://onequiz.games.onefc.com/assets/pvp/ONE_QUIZ_bkg.mp4"
              type="video/mp4"
              loop={true}
            ></source>
          </video>
          <div className="result">{headertext}</div>
          {/* <div className="result_text">
          You answered 5 question correctly in only 15 secounds!
        </div> */}
          <div className="user_result_detail">
            <img
              src="https://onequiz.games.onefc.com/assets/pvp/resultsbox.svg"
              className="img_resultsBox"
            />

            <div className="first_user_box">
              <div className="first_user_img_div">
                <img
                  src={resultsArr.currentUser.avatar}
                  className="first_user_img"
                  alt=""
                ></img>
                 {resultsArr.has_won === "won"  ? (
                <img
                  src="https://onequiz.games.onefc.com/assets/pvp/belt.png"
                  className="img_belt"
                />
              ) : (
                <div />
              )}
              </div>
              <div className="first_userName">
                {resultsArr.currentUser.name}
              </div>
              <div className="first_userPoint">
                {resultsArr.currentUser.points}
              </div>
            </div>
            {/* {resultsArr.has_won === "won" ? (
              <img
                src="hhttps://onequiz.games.onefc.com/assets/pvp/belt.png"
                className="img_belt"
              />
            ) : (
              <div />
            )} */}
            {/* <div className="first_userName">{resultsArr.currentUser.name}</div>
            <div className="first_userPoint">
              {resultsArr.currentUser.points}
            </div> */}
         
          <img src="boxing_gloves_1.png" className="boxing"></img>
          {/* <div className="dotted_border"></div> */}
          <div className="second_user_box">
            <div className="second_user_img_div">
              <img
                src={resultsArr.opponentUser.avatar}
                className="second_user_img"
                alt=""
              ></img>
               {resultsArr.has_won === "lost" ? (
              <img
                src="https://onequiz.games.onefc.com/assets/pvp/belt.png"
                className="img_belt"
              />
            ) : (
              <div />
            )}
            </div>
            <div className="second_userName">
              {resultsArr.opponentUser.name}
            </div>
            <div className="second_userPoint">
              {resultsArr.opponentUser.points}
            </div>
          </div>
          </div>
          <div className="button_box">
            <button
              className="playAgain_button"
              onClick={() => props.history.replace("/lobby")}
            >
              PLAY AGAIN
            </button>
            {/* <button
              className="exit_button"
              onClick={() => props.history.replace("/")}
            >
              EXIT
            </button> */}
          </div>
        </div>
        <div className="overlay3"></div>
      </div>
    );
  }
  return <div className="resultScreen">
     <video
            loop
            muted
            autoPlay
            playsInline
            style={{ position: "absolute", zIndex: "-1" }}
            className="video"
          >
            <source
              src="https://onequiz.games.onefc.com/assets/pvp/ONE_QUIZ_bkg.mp4"
              type="video/mp4"
              loop={true}
            ></source>
          </video>
          <div className="fetchresult">Please wait while we fetch your results..</div></div>;
});

export default ResultScreen;
