import React, { useEffect, useContext, useState } from "react";
import "./styles.scss";
import api from "../../utils/api";
import { QuizContext } from "../ContextProvider";

const HomeScreen = React.memo(function HomeScreen(props) {
  const useQuizContext = useContext(QuizContext);
  const [isTokenReceived, updateIsTokenReceived] = useState(false);
  const [userNameValue, updateUserNameValue] = useState("");
  // const [buttonVisible,updatebuttonVisible] =  useState(false);
  useEffect(() => {
    const fetchHeaders = async () => {
      // var url = window.location;
      // var req = new XMLHttpRequest();
      // req.open("HEAD", url, false);
      // req.send(null);

      const fetchOneTokenFromQueryParam = getQueryStringValue("token");
      useQuizContext.updateOneToken(fetchOneTokenFromQueryParam);
      var oneCode =
        fetchOneTokenFromQueryParam ||
        useQuizContext.oneToken ||
        "4d3436303314c35d7fbb568a46bd0a1b1af820bd72257dfd6b470259a718b5bfuwdIuk0bXt0UFBOLLRwdw752S2VSCk3Q5i3SdJcDznp/tS5okxYFQEeztg8L1JGkzljzDWljno/KkNogR2MIJUJyuxGGpslh6cEYA43nPiMB5iJS8XPDzXY/+CVHzZ2G0S4EOWapj7uS4Fko6jkHzj5Gl0CpocL7mwHbf8hKzkwK3bl/MTmQKiEM1ObFsjqVGN2qJ7X8uS3nUcg/H1+EcmYtutNxoo/jgik+uXoBGIkQpOMWSoAyrPguWlGhVroCNR8k5jGMHtz7kI54OOsqeuRK/KcJrDJu/6px/XvC6VVq1wmDrr0zCCfNK+KvIfN0ipNPONhmQhs1xwPhmG0XRXiG2uwmSZH8zh8uwwvokPrEiCMALdaYAJ7/9P7TONTaOiR7GzWXHLHa/+lqVWsFL+zBuPUa3aivAGgAmaydbys=";
      if (oneCode) {
        oneCode = oneCode.replace("Bearer ", "");
      }
      //alert(oneCode);

      // "4d3436303314c35d7fbb568a46bd0a1b1af820bd72257dfd6b470259a718b5bfuwdIuk0bXt0UFBOLLRwdw752S2VSCk3Q5i3SdJcDznp/tS5okxYFQEeztg8L1JGkzljzDWljno/KkNogR2MIJUJyuxGGpslh6cEYA43nPiMB5iJS8XPDzXY/+CVHzZ2G0S4EOWapj7uS4Fko6jkHzj5Gl0CpocL7mwHbf8hKzkwK3bl/MTmQKiEM1ObFsjqVGN2qJ7X8uS3nUcg/H1+EcmYtutNxoo/jgik+uXoBGIkQpOMWSoAyrPguWlGhVroCNR8k5jGMHtz7kI54OOsqeuRK/KcJrDJu/6px/XvC6VVq1wmDrr0zCCfNK+KvIfN0ipNPONhmQhs1xwPhmG0XRXiG2uwmSZH8zh8uwwvokPrEiCMALdaYAJ7/9P7TONTaOiR7GzWXHLHa/+lqVWsFL+zBuPUa3aivAGgAmaydbys=";
      //"d1a552dfbc02e5f3248d0d497f0e391dce5403df281019ff5770c87baf434ca8AMuhZyoyZGCC2SFbakXjYK+97CsqYyLZk3YczoOGI21NASOIgmAjmjQM7wOzNyhRTYqShnbj9RiOd/BR7lqJAn6iZ22MqoZUG+wKn1kmAyfhX4iminFadn3z6w0zolhVrHN8jlGvbOs5wHxyRymV4fPUCXE6Z+EpI0rDSpaCQCX4AWFUP/Z0kGQHTjtc9PCi9PtB4w7zJdhVS13OpKk/aynJSJWBvHMwWS2uBxe+HWSLBxe/zDo5rYTtRCPF9vdk6306zsd+cGceZHXknUflaPDAVPxqCkkZURL/ABYE7VCVbM07JqqCc30SAtQ2HKoJ+JwNG1O4SD5fAkVTcwZ9FgtEr6Fl0n3NUwefrSsEBLzPWoVANSGrAQltDxwQLxJGY2bhja5oJdKd7q+azziFYQjGltQngrGvNmYXxN4mO56aEuLGfRC8zkCnbGbO4LxvoTOd8mTzYrJPrVqtdcAp8rRoo3d09QA6uULOJqgvzl3xoXYrHtZOYcmrROoSxIpi";
      const action = Object.assign({});
      const action_1 = Object.assign({});

      action.handler = "DECODE_TOKEN";
      action_1.handler = "FETCH_LIVE_QUIZ";

      action.payload = { token: oneCode };

      const response = await api(action);
      const response_1 = await api(action_1);
      if (response_1.status === 200) {
        if(response_1.data && response_1.data.quiz && response_1.data.quiz.length>0){
          useQuizContext.updateLiveQuizData(response_1.data);
        }
        else{
          useQuizContext.updateQuizLive(false)
        }
      }
      if (response.status === 200) {
        updateIsTokenReceived(true);
        useQuizContext.updateUserDetails(response.data.data);
      } else {
        console.log("token decode failed");
      }
    };
    fetchHeaders();
  }, []);

  const handleInputChange = e => {
    updateUserNameValue(e.target.value);
  };

  const showUserNameInputField =
    useQuizContext.userDetails &&
    useQuizContext.userDetails.user_type &&
    useQuizContext.userDetails.user_type === "guest";

  return (
    <div className="homeComponent">
      <video
        loop
        muted
        autoPlay
        playsInline
        style={{ position: "relative" }}
        className="homevideo"
      >
        <source src="https://onequiz.games.onefc.com/assets/iStock-654239190.mp4" type="video/mp4" loop={true}></source>
      </video>
      <video
        loop
        muted
        autoPlay
        playsInline
        className="ringvideo"
      >
        <source src="https://onequiz.games.onefc.com/assets/Comp%201_1.mp4" type="video/mp4" loop={true}></source>
      </video>
      <img src="one logo.png" className="logo_new" alt=""></img>
      {showUserNameInputField ? (
        <input
          type="text"
          value={userNameValue}
          placeholder="Enter your username"
          className="val_username"
          onChange={handleInputChange}
        />
      ) : (
        <div />
      )}

      <div className="playButton">
        {isTokenReceived ? (
          <span
            onClick={() => {
              useQuizContext.updateGuestUserName(userNameValue);
              props.history.push("/lobby");
            }}
          >
            TAP TO PLAY
          </span>
        ) : (
          <div />
        )}
        {/* <span>PLAY</span> */}
      </div>
    </div>
    // <div className="quizOpponents">
    //   <video
    //     loop
    //     muted
    //     autoPlay
    //     playsInline
    //     style={{ position: "relative" }}
    //     className="homevideo"
    //   >
    //     <source src="Comp 1_3.mp4" type="video/mp4" loop={true}></source>
    //   </video>
    //   <img src="one logo.png" className="logo_new" alt=""></img>

    //   {showUserNameInputField ? (
    //     <input
    //       type="text"
    //       value={userNameValue}
    //       placeholder="Enter your username"
    //       className="val_username"
    //       onChange={handleInputChange}
    //     />
    //   ) : (
    //     <div />
    //   )}

    //   <div className="playButton">
    //     {isTokenReceived ? (
    //       <span
    //         onClick={() => {
    //           useQuizContext.updateGuestUserName(userNameValue);
    //           props.history.push("/lobby");
    //         }}
    //       >
    //         TAP TO PLAY
    //       </span>
    //     ) : (
    //       <div />
    //     )}
    //     {/* <span>PLAY</span> */}
    //   </div>
    // </div>
  );
});

function getQueryStringValue(key) {
  return window.decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        `^(?:.*[&\\?]${window
          .encodeURIComponent(key)
          .replace(/[\.\+\*]/g, "\\$&")}(?:\\=([^&]*))?)?.*$`,
        "i"
      ),
      "$1"
    )
  );
}

export default HomeScreen;
