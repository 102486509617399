import React, { useState } from "react";

export const QuizContext = React.createContext();

export const ContextProvider = props => {
  const [roomId, updateRoomId] = useState(-1);
  const [userId, updateUserId] = useState(-1);
  const [userDetails, updateUserDetails] = useState({});
  const [liveQuizData, updateLiveQuizData] = useState({});
  const [roomDetails, updateRoomDetails] = useState({});
  const [guestUserName, updateGuestUserName] = useState("");
  const [oneToken, updateOneToken] = useState("");
  const [quizLive,updateQuizLive] = useState(true)

  return (
    <QuizContext.Provider
      value={{
        roomId,
        updateRoomId,
        userId,
        updateUserId,
        userDetails,
        updateUserDetails,
        liveQuizData,
        updateLiveQuizData,
        roomDetails,
        updateRoomDetails,
        guestUserName,
        updateGuestUserName,
        oneToken,
        updateOneToken,
        quizLive,
        updateQuizLive
      }}
    >
      {props.children}
    </QuizContext.Provider>
  );
};
