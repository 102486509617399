const routes = {
  GET_QUESTIONS: {
    path: "/fetchQuestions",
    method: "POST"
  },
  MATCH_PLAYERS: {
    path: "/matchPlayers",
    method: "post"
  },
  FETCH_ROOM: {
    path: "/fetchRoom",
    method: "post"
  },
  POST_ANSWER: {
    path: "/saveAnswer",
    method: "post"
  },
  FETCH_LIVE_SCORES: {
    path: "/fetchCurrentScore",
    method: "post"
  },
  FETCH_RESULTS: {
    path: "/fetchResultScreen",
    method: "post"
  },
  DECODE_TOKEN: {
    path: "/decodeToken",
    method: "post"
  },
  FETCH_LIVE_QUIZ: {
    path: "/fetchActiveQuiz",
    method: "get"
  },
  MATCH_WITH_BOT: {
    path: "/matchWithBot",
    method: "post"
  }
};

export default routes;
