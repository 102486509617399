import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import "./styles.scss";
import api from "../../utils/api";
import { QuizContext } from "../ContextProvider";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const delay = ms => new Promise(res => setTimeout(res, ms));

//const LobbyScreen = React.memo(function LobbyScreen(props) {
const LobbyScreen = props => {
  const useQuizContext = useContext(QuizContext);
  const [counter, setCounter] = React.useState(30);
  const [ready, setReady] = React.useState(true);
  const [start, setStart] = React.useState();
  const [roomId, updateRoomId] = useState(-1);
  const [opponentProfile, updateOpponentProfile] = useState({});
  // const [quizLive, updateQuizlive] = useState(true);
  //const user_id = Math.floor(Math.random() * 10 + 1);
  const quiz_id =
    useQuizContext.liveQuizData &&
    useQuizContext.liveQuizData.quiz &&
    useQuizContext.liveQuizData.quiz[0].id;
  const { user_id, user_type, analytics_id } = useQuizContext.userDetails || {};
  const { name, email, country, avatar } =
    (useQuizContext.userDetails && useQuizContext.userDetails.user_data) || {};
  const { guestUserName } = useQuizContext;

  useEffect(() => {
    const fetchPlayerMatching = async () => {
      const action = Object.assign({});
      action.handler = "MATCH_PLAYERS";
      action.payload = {
        user_id: user_id,
        user_type,
        name: guestUserName ? guestUserName : name,
        email,
        country,
        analytics_id,
        avatar,
        quiz_id
      };
      const response = await api(action);
      if (response.status === 200) {
        const room_id = response.data.data.room_id;
        updateRoomId(room_id);
        useQuizContext.updateRoomId(room_id);
        useQuizContext.updateUserId(user_id);
      } else {
        useQuizContext.updateQuizLive(false)
        console.log("match players fail");
      }
    };
    fetchPlayerMatching();
  }, []);

  useEffect(() => {
    let pollCounter = 6;

    const fetchRoom = async () => {
      const action = Object.assign({});

      action.handler = "FETCH_ROOM";
      action.payload = { room_id: roomId };
      while (window.location.href.includes("lobby") && pollCounter > 0) {
        const response = await api(action);
        pollCounter = pollCounter - 1;
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.data.state === "ready") {
            console.log(response.data);
            pollCounter = 0;

            let opponentDetailsUtil = response.data.data.users;

            let userArrUtil = Object.keys(opponentDetailsUtil);
            if (userArrUtil.length > 1) {
              userArrUtil = userArrUtil.filter(item => item !== user_id);
              opponentDetailsUtil = opponentDetailsUtil[userArrUtil];
              updateOpponentProfile(opponentDetailsUtil);
              useQuizContext.updateRoomDetails(response.data.data);
            }

            setReady(false);
            const waitTimeBeforeStart =
              new Date(response.data.data.startTime) - dayjs.utc();
            console.log(waitTimeBeforeStart);
            await delay(waitTimeBeforeStart);

            setCounter(0);
            setTimeout(() => setStart("Ready.."), 1000);
            setTimeout(() => setStart("Ready, Steady.."), 2000);
            setTimeout(() => setStart("Ready, Steady, Go"), 3000);
            setTimeout(() => props.history.replace("/play"), 4000);
          }
        } else {
          console.log("fetch room failed");
        }
        await delay(4000);
      }

      if (pollCounter === 0 && ready) {
        const action_1 = Object.assign({});
        action_1.handler = "MATCH_WITH_BOT";
        action_1.payload = { quiz_id };
        const response_1 = await api(action_1);
        if (response_1.status === 200) {
          if (response_1.data.data.state === "ready") {
            const action = Object.assign({});
            action.handler = "FETCH_ROOM";
            action.payload = { room_id: response_1.data.data.room_id };
            const response = await api(action);
            if (response.status === 200) {
              let opponentDetailsUtil = response.data.data.users;

              let userArrUtil = Object.keys(opponentDetailsUtil);
              if (userArrUtil.length > 1) {
                userArrUtil = userArrUtil.filter(item => item !== user_id);
                opponentDetailsUtil = opponentDetailsUtil[userArrUtil];
                updateOpponentProfile(opponentDetailsUtil);
                useQuizContext.updateRoomDetails(response.data.data);
              }

              setReady(false);
              const waitTimeBeforeStart =
                new Date(response.data.data.startTime) - dayjs.utc();
              console.log(waitTimeBeforeStart);
              await delay(waitTimeBeforeStart);

              setCounter(0);
              setTimeout(() => setStart("Ready.."), 1000);
              setTimeout(() => setStart("Ready, Steady.."), 2000);
              setTimeout(() => setStart("Ready, Steady, Go"), 3000);
              setTimeout(() => props.history.replace("/play"), 4000);
              return;
            }
          }
        }
      }
    };
    if (roomId > 0) {
      fetchRoom();
    }
  }, [roomId]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    // if (counter == 0) {
    //   setReady(false);
    //   setTimeout(() => setStart("Ready.."), 1000);
    //   setTimeout(() => setStart("Ready, Steady.."), 2000);
    //   setTimeout(() => setStart("Ready, Steady, Go"), 3000);
    //   setTimeout(() => props.history.push("/play"), 4000);
    // }
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return (
    <div>
    {useQuizContext.quizLive?(<div className="quizOpponents">
      <div className="hdr_lobby">
        <video
          loop
          muted
          autoPlay
          playsInline
          style={{ position: "absolute", zIndex: "-1" }}
          className="hdrVideo"
        >
          <source
            src="https://onequiz.games.onefc.com/assets/pvp/ONE_QUIZ_bkg.mp4"
            type="video/mp4"
            loop={true}
          ></source>
        </video>

        <div className="profileDetail">
          <div className="root_ring">
            <img src={avatar} className="profile" alt=""></img>
          </div>
          <div className="profileName">
            {guestUserName ? guestUserName : name}
          </div>
        </div>
      </div>
      {/* <div>
        <img src="rectangle.png" className="overlay"></img>
      </div> */}
      <img src="golden line.png" className="goldenline"></img>
      <img
        src="https://onequiz.games.onefc.com/assets/pvp/ONE Trivia Challenge Logo.png"
        className="lobbyLogo"
        alt=""
      ></img>
      <div className="footer_lobby">
        <video
          loop
          muted
          autoPlay
          playsInline
          style={{
            position: "absolute",
            zIndex: "-1",
            top: "50%",
            transform: "rotate(180deg)"
          }}
          className="footervideo"
        >
          <source
            src="https://onequiz.games.onefc.com/assets/pvp/ONE_QUIZ_bkg.mp4"
            type="video/mp4"
            loop={true}
          ></source>
        </video>

        {ready ? (
          <div>
            <div className="bottomTxt">Finding Opponent</div>
            <div className="counterSec">{counter}s</div>
          </div>
        ) : (
          <div>
            <div className="bottomTxt">{start}</div>
            <div className="oppProfileDetail">
              <div className="root_btm_ring">
                <img
                  src={opponentProfile.avatar}
                  className="oppProfile"
                  alt=""
                ></img>
              </div>
              <div className="oppProfileName">{opponentProfile.name}</div>
            </div>
          </div>
        )}
      </div>
      {/* <div>
        <img src="rectangle_copy_2.png" className="overlay1"></img>
      </div> */}
      {/* <div className="topBg">
        <img src="/assets/Top Lobby.png" alt=""></img>
      </div> */}

      {/* <div className="bottomBg">
        <img src="/assets/Bottom Lobby.png" alt=""></img>
      </div>
      <img
        src="/assets/ONE Trivia Challenge Logo.png"
        className="lobbyLogo"
        alt=""
      ></img>
      {ready ? (
        <div>
          <div className="bottomTxt">Finding Opponent</div>
          <div className="counterSec">{counter}s</div>
        </div>
      ) : (
        <div>
          <div className="ready">{start}</div>
          <div className="oppProfileDetail">
            <div>
              <img
                src="/assets/oppositeprofile.png"
                className="oppProfile"
                alt=""
              ></img>
            </div>
            <div className="oppProfileName">Dontae Little</div>
          </div>
        </div>
      )} */}

      {/* <button onClick={() => props.history.push("/")}>Nav to lobby</button> */}
    </div>):(
      <div>No quizzes live  now, please come back after some time</div>
    )}
    
    </div>
  );
};

export default LobbyScreen;
