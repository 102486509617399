import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import HomeScreen from "../shells/Home";
import LobbyScreen from "../shells/Lobby";
import PlayScreen from "../shells/PlayScreen";
import ResultScreen from "../shells/Result";
import { ContextProvider } from "../shells/ContextProvider";
import history from "./history";
import Music from "./music";

function Root() {
  return (
    <ContextProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Music>
            <Route exact path="/lobby" component={LobbyScreen} />
            <Route exact path="/play" component={PlayScreen} />
            <Route exact path="/result" component={ResultScreen} />
          </Music>
        </Switch>
      </Router>
    </ContextProvider>
  );
}

export default Root;
